.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Raleway Regular";
}

.footer-a {
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-family: "Raleway Regular";
}

.footer-div {
  margin-top: 2rem;
}
